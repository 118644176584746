<template>
  <div class="page-content">
    <page-breadcrumb title="Listening" />
    <section class="py-2">
      <b-row>
        <b-col md="10">
          <h2 class="mb-2">Listening Topic Management</h2>
        </b-col>
      </b-row>
    </section>
    <section id="draggable-cards">
      <draggable
        :list="listening_topics"
        @change="confirmChangeDisplayOrder"
        class="row match-height"
      >
        <b-col
          v-for="(item, index) in listening_topics"
          :key="index"
          class="col-lg-2 col-md-2 col-12"
        >
          <b-link
            :to="{
              name: 'listening-topic-detail',
              params: { topic_id: item._id },
            }"
          >
            <b-card class="text-center">
              Level {{ item["display_order"] + 1 }}
            </b-card>
          </b-link>
        </b-col>
      </draggable>
    </section>

    <div class="text-center">
      <topic-create-form />
    </div>
  </div>
</template>

<script>
import TopicCreateForm from "./_components/TopicCreateForm.vue";
import TopicCard from "./_components/TopicCard.vue";
import service from "../service";
import draggable from "vuedraggable";

export default {
  components: {
    TopicCreateForm,
    TopicCard,
    draggable,
  },
  data() {
    return {
      listening_topics: null,
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      let response = await service.getAll({});
      if (response.data.type === "DATA") {
        this.listening_topics = response.data.data.list;
      }
    },
    confirmChangeDisplayOrder() {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.changeDisplayOrder();
          } else {
            this.getAll();
          }
        });
    },
    async changeDisplayOrder() {
      let topic_ids = [];
      this.listening_topics.forEach((topic) => {
        topic_ids.push(topic._id);
      });
      await service.changeDisplayOrder({ topic_ids });
      this.getAll();
    },
  },
};
</script>
<style scoped>
.badge-secondary {
  background-color: #f8f8f8;
}
</style>
