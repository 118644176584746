<template>
  <div>
    <b-button variant="primary" @click="create">
      <b-spinner v-if="isLoading" small class="mr-50" />
      <feather-icon v-else icon="PlusIcon" class="mr-50" />
      <span class="align-middle">Add Level</span>
    </b-button>
  </div>
</template>

<script>
import service from "../../service";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async create() {
      this.isLoading = true;
      let new_data = await service.create({});
      this.isLoading = false;
      if (new_data) {
        this.$router.push({
          name: "listening-topic-detail",
          params: { topic_id: new_data._id },
        });
      }
    },
  },
};
</script>
